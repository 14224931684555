import Card from '@root/core/src/components/card';
import Colors from '@root/brand/src/utils/colors';
import CookieStorageService from '@root/core/src/services/cookie-storage-service';
import IconCell from '@root/core/src/components/icon-cell';
import PropTypes from '@root/vendor/prop-types';
import QuotesContext from '@root/quotes/src/models/quotes-context';
import Responsive from '@root/core/src/utils/responsive';
import SceneContent from '@root/core/src/components/scene-content';
import SceneWrapper from '@root/core/src/components/scene-wrapper';
import calendar from '@root/quotes/src/assets/calendar.svg';
import dayjs from '@root/vendor/dayjs';
import number1 from '@root/quotes/src/assets/number-1.svg';
import number2 from '@root/quotes/src/assets/number-2.svg';
import reviewInProgressImage from '@root/quotes/src/assets/review-in-progress/review-in-progress.png';
import reviewInProgressImage2x from '@root/quotes/src/assets/review-in-progress/review-in-progress@2x.png';
import reviewInProgressImage3x from '@root/quotes/src/assets/review-in-progress/review-in-progress@3x.png';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useEffect, useState } from '@root/vendor/react';

export const UNDERWRITING_PENDING_VIEWED_COOKIE = 'underwriting_pending.viewed';

export default function UnderwritingPendingLayout({
  quotesContext,
  analyticsContext,
}) {
  useAnalytics(analyticsContext);

  const estimatedCompleteAt = quotesContext?.underwritingDecision.estimatedCompleteAt;
  const estimatedCompletionDateString = estimatedCompleteAt && dayjs(estimatedCompleteAt).format('MMMM D');

  const [reviewInProgress, setReviewInProgress] = useState(false);

  useEffect(() => {
    const isFirstView = !CookieStorageService.get(UNDERWRITING_PENDING_VIEWED_COOKIE);

    if (isFirstView) {
      CookieStorageService.set(UNDERWRITING_PENDING_VIEWED_COOKIE, true);
    } else {
      setReviewInProgress(true);
    }
  }, [setReviewInProgress]);

  const heading = reviewInProgress ? 'Review in progress' : 'We need to review your application';

  return (
    <SceneWrapper>
      <SceneContent>
        <div css={styles.imageWrapper}>
          <img
            alt={'review in progress'}
            css={styles.image}
            src={reviewInProgressImage}
            srcSet={`${reviewInProgressImage, reviewInProgressImage2x, reviewInProgressImage3x}`}
          />
        </div>
        <h1 css={styles.heading}>
          {heading}
        </h1>
        <h2 css={styles.subtext}>
          {'Here‘s how this works'}
        </h2>
        <div css={styles.cellContainer}>
          <IconCell
            icon={number1}
            leftIconContainerStyles={styles.iconContainer}
            primaryText={'Internal review'}
            primaryTextStyles={styles.primaryText}
            secondaryText={'Our team will review the information on your application.'}
            secondaryTextStyles={styles.secondaryText}
          />
          <IconCell
            icon={number2}
            leftIconContainerStyles={styles.iconContainer}
            primaryText={'Get a quote'}
            primaryTextStyles={styles.primaryText}
            secondaryText={'If everything looks good we’ll offer you a quote. Keep an eye out for an email with more instructions.'}
            secondaryTextStyles={styles.secondaryText}
          />
        </div>
        {estimatedCompletionDateString && (
          <Card cssOverrides={styles.card}>
            <IconCell
              cssOverrides={styles.completionCellContainer}
              icon={calendar}
              leftIconContainerStyles={styles.completionIconContainer}
              primaryText={'Estimated review completion date'}
              primaryTextStyles={styles.completionPrimaryText}
              rightContainerStyles={styles.completionIconContainer}
              secondaryText={estimatedCompletionDateString}
              secondaryTextStyles={styles.completionSecondaryText}
            />
          </Card>
        )}
      </SceneContent>
    </SceneWrapper>
  );
}

UnderwritingPendingLayout.propTypes = {
  analyticsContext: PropTypes.string.isRequired,
  quotesContext: PropTypes.instanceOf(QuotesContext).isRequired,
};

const styles = StyleSheet.create({
  heading: {
    ...Theme.displayHeading3(),
  },
  subtext: {
    ...Theme.heading3(),
    margin: '24px 0',
  },
  primaryText: {
    ...Theme.heading3(),
    fontSize: 20,
  },
  secondaryText: {
    ...Theme.paragraph1(),
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
  },
  image: {
    width: 200,
  },
  cellContainer: {
    marginBottom: 10,
  },
  iconContainer: {
    alignItems: 'flex-start',
    paddingTop: 13,
  },
  completionCellContainer: {
    marginBottom: 0,
  },
  completionPrimaryText: {
    ...Theme.paragraph2(),
    color: Colors.rootOrange(),
  },
  completionSecondaryText: {
    ...Theme.heading5(),
  },
  completionIconContainer: {
    borderTop: 'none',
  },
  card: {
    padding: '0px 20px',
    ...Responsive.md({
      padding: '0px 20px',
    }),
  },
});
