import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageOptionsToggle from '@root/quotes/src/components/coverage/coverage-option-toggle';
import Money from '@root/core/src/models/money';
import PipMnLostWagesExplanationLinkModal from '@root/quotes/src/components/modals/pip-mn-lost-wages-explanation-link-modal';
import PropTypes from '@root/vendor/prop-types';
import QuotesSelect from '@root/quotes/src/components/form-elements/quotes-select';
import React, { useCallback, useEffect, useRef } from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

const SYMBOL = Coverage.Symbols.PERSONAL_INJURY_PROTECTION;

export default function PipWorkLoss({
  coverageSelections,
  dropdownIndicator,
  mobileSelectedOptionStyles,
  pipCoverages,
  primaryBrandColor,
  onClose,
  onCoverageChange,
  onOpen,
  secondaryBrandColor,
  coverage,
  profileParams,
}) {
  const isMounted = useRef(false);
  const { coverageOptions } = coverage;

  const getSelectedCoverage = (
    newValue,
  ) => {
    return pipCoverages.find((selection) => {
      return selection.id === newValue;
    });
  };

  const onDropDownChange = (newValue) => {
    onChange(newValue);
  };

  const onToggleChange = (option) => {
    const newValue = coverageOptions[option] === Coverage.CoverageOptionChoice.YES ? Coverage.CoverageOptionChoice.NO : Coverage.CoverageOptionChoice.YES;
    const selectedCoverage = pipCoverages.find((selection) => {
      return selection.coverageOptions.workLossExcluded === newValue && selection.coverageOptions.stacked === coverage.coverageOptions.stacked;
    });
    onCoverageChange(selectedCoverage.id, SYMBOL);
  };

  const onChange = (newValue) => {
    const selectedCoverage = getSelectedCoverage(newValue);
    onCoverageChange(selectedCoverage.id, SYMBOL);
  };

  const getDropDownLabels = (selection) => {
    if (selection.coverageOptions.stacked === Coverage.CoverageOptionChoice.YES) {
      return `${Money.fromDollars(selection.perPerson).formattedDollars()} medical loss / ${Money.fromDollars(selection.perPerson).formattedDollars()} essential services / stacked`;
    }
    if (selection.coverageOptions.stacked === Coverage.CoverageOptionChoice.NO) {
      return `${Money.fromDollars(selection.perPerson).formattedDollars()} medical loss / ${Money.fromDollars(selection.perPerson).formattedDollars()} essential services`;
    }

    return selection.label.replace(/ applies.*/, '');
  };

  const dropDownSelections = useCallback(() => {
    const selections = coverageSelections.pip.filter((selection) => {
      if (selection.coverageOptions.stacked === Coverage.CoverageOptionChoice.YES && profileParams.getAllSelectedVehicles().length < 2) {
        return false;
      }
      // This is only for users who changed the age of the PNI driver to under 60
      if (selection.coverageOptions.workLossExcluded === Coverage.CoverageOptionChoice.YES && profileParams.getPniDriver().age < 60) {
        return false;
      }

      return selection.coverageOptions.workLossExcluded === coverage.coverageOptions.workLossExcluded;
    }).map((selection) => {
      return {
        ...selection,
        label: getDropDownLabels(selection),
      };
    });
    return selections;
  }, [coverage, coverageSelections, profileParams]);

  useEffect(() => {
    if (isMounted.current) { return; }

    isMounted.current = true;

    const selections = dropDownSelections();
    if (!selections.some((selection) => selection.value === coverage.id)) {
      onCoverageChange(selections[0].value, SYMBOL);
    }
  }, [coverage.id, dropDownSelections, onCoverageChange]);

  return (
    <>
      <QuotesSelect
        dropdownIndicator={dropdownIndicator}
        dropdownIndicatorColor={primaryBrandColor}
        inputId={'pip-coverage-select'}
        inputName={'pip-coverage-select'}
        mobileSelectedOptionStyles={mobileSelectedOptionStyles}
        onChange={onDropDownChange}
        onClose={onClose}
        onOpen={onOpen}
        options={dropDownSelections()}
        optionStyles={{ ':hover, :hover > span': { background: primaryBrandColor } }}
        placeholder={'Select option'}
        selectedValue={coverage.id}
      >
        {
          profileParams.getPniDriver().age >= 60 &&
          <>
            <CoverageOptionsToggle
              circleSelectedStylesOverrides={primaryBrandColor && { borderColor: primaryBrandColor }}
              containerStyleOverrides={styles.containerCoverageOptionsTop}
              description={'Include work loss'}
              isSelected={coverageOptions.workLossExcluded === Coverage.CoverageOptionChoice.NO}
              onToggleChange={() => onToggleChange(Coverage.CoverageOptions.WORK_LOSS_EXCLUDED)}
              toggleSelectedStylesOverrides={primaryBrandColor && { background: primaryBrandColor }}
              toggleText={coverageOptions.workLossExcluded === Coverage.CoverageOptionChoice.NO ? 'Yes' : 'No'}
            />
            <PipMnLostWagesExplanationLinkModal
              primaryBrandColor={primaryBrandColor}
              secondaryBrandColor={secondaryBrandColor}
            />
          </>
        }
      </QuotesSelect>
    </>
  );
}

PipWorkLoss.propTypes = {
  coverage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    deductible: PropTypes.number,
    coverageOptions: PropTypes.shape({
      residentRelativesIncluded: PropTypes.string.isRequired,
      workLossExcluded: PropTypes.string.isRequired,
      stacked: PropTypes.string.isRequired,
    }),
  }),
  coverageSelections: PropTypes.shape({
    pip: PropTypes.arrayOf(PropTypes.shape({
      coverageOptions: PropTypes.object,
    })),
  }),
  dropdownIndicator: PropTypes.string,
  mobileSelectedOptionStyles: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onCoverageChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  pipCoverages: PropTypes.arrayOf(PropTypes.shape({
    coverageOptions: PropTypes.shape({
      residentRelativesIncluded: PropTypes.string.isRequired,
      workLossExcluded: PropTypes.string.isRequired,
    }),
    deductible: PropTypes.number,
    id: PropTypes.string.isRequired,
  })),
  primaryBrandColor: PropTypes.string,
  profileParams: PropTypes.object,
  secondaryBrandColor: PropTypes.string,
};

const styles = StyleSheet.create({
  containerCoverageOptionsTop: {
    marginTop: 32,
  },
  containerCoverageOptionsBottom: {
    marginTop: 24,
  },
});
