import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageAmountLabel from '@root/auto-pricing/src/utils/coverage-amount-label';
import CoverageTitle from '@root/quotes/src/components/coverage/coverage-title';
import Link from '@root/core/src/components/link';
import Money from '@root/core/src/models/money';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import Quote from '@root/quotes/src/models/quote';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { Layout } from '@root/brand/src/utils/layout';
import { isMobileBrowser } from '@root/core/src/utils/detect-mobile-browser';
import { pluralize } from '@root/core/src/utils/strings';
import { useHistory } from '@root/core/src/components/route';

export const getAcceptedCoverageBySymbol = (quote, market, symbol) => {
  const coverage = quote.getCoverage(symbol);
  const coverageName = Coverage.getLocaleName({
    market,
    symbol,
  });

  return coverage && !coverage.declined ? [coverage, coverageName] : [null, coverageName];
};

export default function QuoteReviewCard({
  headerOverrideStyles,
  mobileLinkStyles,
  profileParams,
  quote,
  tortSelection,
  manualTortMarket = false,
  onEditPlan,
  isRideshareMarket = false,
  linkStyles,
}) {
  const isUserOnMobile = isMobileBrowser();
  const [showFullCoverages, setShowFullCoverages] = useState(!isUserOnMobile);
  const { trackClick } = useAnalytics('QUOTE_REVIEW_CARD');
  const history = useHistory();
  const market = profileParams.market();

  const handleEditPlanClick = () => {
    trackClick('EDIT_PLAN_CLICKED');
    if (onEditPlan) {
      onEditPlan();
    } else {
      history.push(`/quotes/selected/${quote.id}`);
    }
  };

  const handleShowCoveragesClicked = () => {
    trackClick(`${showFullCoverages ? 'HIDE' : 'SHOW'}_COVERAGES_CLICKED`);
    setShowFullCoverages((previousShowFullCoverages) => !previousShowFullCoverages);
  };

  const createCoverageContainerCell = (heading, children, subHeading) => {
    if (Array.isArray(children) && children.length === 0) { return null; }

    return (
      <div css={styles.coverageContainerCell}>
        {heading && <p css={styles.coverageName}>{heading}</p>}
        {children}
        {subHeading && <p css={styles.remainderText}>{subHeading}</p>}
      </div>
    );
  };

  const addComma = (currentIndex, elementsToShow, totalElementsLength) => {
    return currentIndex < elementsToShow - 1 && currentIndex !== totalElementsLength - 1 ? ', ' : '';
  };

  const renderDrivers = (driversToShow = 4) => {
    const { drivers } = profileParams;

    const driverNameMappings = drivers.map((driver, driverIndex) => {
      if (driverIndex >= driversToShow) { return null; }

      let driverNameText = `${driver.firstName} ${driver.lastName}`;
      driverNameText += addComma(driverIndex, driversToShow, drivers.length);

      return <span key={driverIndex}>{driverNameText}</span>;
    });

    const driversRemaining = drivers.length - driversToShow;
    let driversRemainingText = '';

    if (driversRemaining > 0) {
      driversRemainingText = `+${driversRemaining} `;
      driversRemainingText += pluralize('driver', driversRemaining);
    }

    return createCoverageContainerCell('Drivers', driverNameMappings, driversRemainingText);
  };

  const renderVehicles = (vehiclesToShow = 4) => {
    const { vehicles } = profileParams;

    const vehiclesMapping = vehicles.map((vehicle, vehicleIndex) => {
      if (vehicleIndex >= vehiclesToShow) { return null; }

      let vehicleText = vehicle.makeAndModelOrVin();
      vehicleText += addComma(vehicleIndex, vehiclesToShow, vehicles.length);

      return <span key={vehicleIndex}>{vehicleText}</span>;
    });

    const vehiclesRemaining = vehicles.length - vehiclesToShow;
    let vehiclesRemainingText = '';

    if (vehiclesRemaining > 0) {
      vehiclesRemainingText = `+${vehiclesRemaining} `;
      vehiclesRemainingText += pluralize('vehicle', vehiclesRemaining);
    }

    return createCoverageContainerCell('Vehicles', vehiclesMapping, vehiclesRemainingText);
  };

  const renderDeductibles = () => {
    const deductiblesMapping = [];

    Coverage.DeductiblesCoverageTypes.forEach((symbol, idx) => {
      const [coverage] = getAcceptedCoverageBySymbol(quote, market, symbol);
      if (!coverage) { return; }

      const coverageTitle = (
        <CoverageTitle
          coverage={coverage}
          market={market}
        />
      );

      const deductibleAmount = Money.fromDollars(coverage.deductible).formattedDollars();
      deductiblesMapping.push(
        <p
          css={styles.coverageDetails}
          key={idx}
        >{coverageTitle} {deductibleAmount}
        </p>
      );
    });

    return createCoverageContainerCell('Deductibles', deductiblesMapping);
  };

  const renderLiabilityCoverages = () => {
    const coverageMappings = [];

    Coverage.LiabilityCoverageTypes.forEach((symbol, idx) => {
      const [coverage] = getAcceptedCoverageBySymbol(quote, market, symbol);
      if (!coverage) { return; }

      const coverageTitle = (
        <CoverageTitle
          coverage={coverage}
          market={market}
        />
      );

      const perPersonAmount = coverage.perPerson ? `${Money.fromDollars(coverage.perPerson).formattedKiloDollars()}/` : '';
      const perOccurrenceAmount = Money.fromDollars(coverage.perOccurrence).formattedKiloDollars();

      coverageMappings.push(
        <p
          css={styles.coverageDetails}
          key={idx}
        >
          {coverageTitle} {perPersonAmount}{perOccurrenceAmount}
        </p>
      );
    });

    return createCoverageContainerCell('Liability Coverage', coverageMappings);
  };

  const renderNonLiabilityCoverages = () => {
    const coverageMappings = [];

    Coverage.NonLiabilityCoverageTypes.forEach((symbol, idx) => {
      const [coverage] = getAcceptedCoverageBySymbol(quote, market, symbol);
      if (!coverage) { return; }

      let perPersonAmount = coverage.perPerson ? `${Money.fromDollars(coverage.perPerson).formattedDollars()} per person` : '';

      let perOccurrenceAmount = coverage.perOccurrence ? `${Money.fromDollars(coverage.perOccurrence).formattedDollars()} per accident` : '';
      let deductibleAmount = coverage.deductible !== null && coverage.deductible !== undefined ? `${Money.fromDollars(coverage.deductible).formattedDollars()} deductible` : '';
      const coverageOptionLabel = CoverageAmountLabel.buildCoverageOptionsLabel(coverage.coverageOptions, coverage.symbol, market);

      if (market === 'MN' && symbol === Coverage.Symbols.PERSONAL_INJURY_PROTECTION) {
        perPersonAmount = `${Money.fromDollars(coverage.perPerson).formattedDollars()} medical loss / ${Money.fromDollars(coverage.perPerson).formattedDollars()} essential services`;
        perOccurrenceAmount = '';
        deductibleAmount = '';
      }

      const coverageTitle = (
        <CoverageTitle
          coverage={coverage}
          market={market}
        />
      );

      const coverageContainerChildren = (
        <div>
          <p css={styles.coverageDetails}>{perPersonAmount}</p>
          <p css={styles.coverageDetails}>{perOccurrenceAmount}</p>
          <p css={styles.coverageDetails}>{deductibleAmount}</p>
          <p css={styles.coverageDetails}>{coverageOptionLabel}</p>
        </div>
      );

      coverageMappings.push(
        <div key={idx}>
          {createCoverageContainerCell(coverageTitle, coverageContainerChildren)}
        </div>
      );
    });

    return coverageMappings;
  };

  const renderRentalCoverages = () => {
    const [coverage] = getAcceptedCoverageBySymbol(quote, market, Coverage.Symbols.RENTAL);
    if (!coverage) { return; }

    const perDayAmount = `${Money.fromDollars(coverage.perDay).formattedDollars()} per day`;

    const coverageTitle = (
      <CoverageTitle
        coverage={coverage}
        market={market}
      />
    );
    const coverageContainerChildren = <p css={styles.coverageDetails}>{perDayAmount}</p>;
    return createCoverageContainerCell(coverageTitle, coverageContainerChildren);
  };

  const renderRoadsideCoverages = () => {
    const [coverage] = getAcceptedCoverageBySymbol(quote, market, Coverage.Symbols.ROADSIDE);

    if (!coverage) { return; }

    let detailText = 'Included with this policy';

    if (coverage.perOccurrence) {
      detailText = `${Money.fromDollars(coverage.perOccurrence).formattedDollars()} per incident`;
    }

    const coverageTitle = (
      <CoverageTitle
        coverage={coverage}
        market={market}
      />
    );
    const coverageContainerChildren = <p css={styles.coverageDetails}>{detailText}</p>;
    return createCoverageContainerCell(coverageTitle, coverageContainerChildren);
  };

  const renderTort = () => {
    if (!manualTortMarket || !tortSelection) { return null; }

    const coverageContainerChildren = <p css={styles.coverageDetails}>{`${tortSelection === 'full' ? 'Full' : 'Limited'} Tort`}</p>;
    return createCoverageContainerCell('Tort Selection', coverageContainerChildren);
  };

  const renderRideshare = () => {
    const coverageContainerChildren = <p css={styles.coverageDetails}>{'Included'}</p>;
    return isRideshareMarket && profileParams.rideshare && createCoverageContainerCell('Rideshare & Delivery', coverageContainerChildren);
  };

  return (
    <div css={styles.container}>
      <div css={[styles.headerContainer, headerOverrideStyles]}>
        <p css={styles.headerText}>Plan overview</p>
        <Link
          css={[styles.headerText, linkStyles]}
          onClick={handleEditPlanClick}
        >Edit plan
        </Link>
      </div>
      <div css={styles.coveragesContainer}>
        {renderDrivers()}
        {renderVehicles()}
        {
          showFullCoverages &&
          <div>
            {renderDeductibles()}
            {renderLiabilityCoverages()}
            {renderNonLiabilityCoverages()}
            {renderRentalCoverages()}
            {renderRideshare()}
            {renderRoadsideCoverages()}
            {renderTort()}
          </div>
        }
        {
          isUserOnMobile &&
          <div css={styles.coverageContainerCell}>
            <Link
              css={[styles.link, mobileLinkStyles]}
              onClick={handleShowCoveragesClicked}
            >{showFullCoverages ? 'Hide coverages' : 'View coverages'}
            </Link>
          </div>
        }
      </div>
    </div>
  );
}

QuoteReviewCard.propTypes = {
  headerOverrideStyles: PropTypes.object,
  isRideshareMarket: PropTypes.bool,
  linkStyles: PropTypes.object,
  manualTortMarket: PropTypes.bool,
  mobileLinkStyles: PropTypes.object,
  onEditPlan: PropTypes.func,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  quote: PropTypes.instanceOf(Quote).isRequired,
  tortSelection: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  coveragesContainer: {
    borderRadius: '0px 0px 6px 6px',
    border: '1px solid',
    borderColor: Colors.gray40(),
    padding: Layout.CONTAINER_PADDING,
    paddingBottom: 12,
  },
  coverageContainerCell: {
    paddingBottom: 12,
  },
  coverageName: {
    ...Theme.paragraph2(),
    marginBottom: 0,
  },
  coverageDetails: {
    marginBottom: 0,
    ...Responsive.lessThanLg({
      lineHeight: '19px',
    }),
  },
  headerContainer: {
    backgroundColor: Colors.darkBlue(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: Layout.CONTAINER_PADDING,
    borderRadius: '6px 6px 0px 0px',
  },
  headerText: {
    ...Theme.paragraph1(),
    color: Colors.white(),
    marginBottom: 0,
  },
  remainderText: {
    ...Theme.paragraph1(),
    marginBottom: 0,
  },
  link: {
    ...Theme.textLink(),
    textDecoration: 'none',
  },
});
