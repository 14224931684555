import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';
import ServerUtils from '@root/core/src/api/server-utils';

export async function getQuotesContext() {
  const { data, rawResponse } = await ServerUtils.authenticatedGet('/web_api/contexts/quotes_context', {
    expectedResponse: [200, 204],
  });

  if (rawResponse.status === 204) {
    return {};
  }

  return data;
}

export async function createCustomQuote(params) {
  const response = await ServerUtils.authenticatedPost('/web_api/quotes', {
    expectedResponse: [201],
    body: {
      ...params,
    },
  });

  if (response.rawResponse.status === 201) {
    return response.data;
  }

  return response;
}

export async function createClientQuoteCalculations(clientCalculations, quoteId) {
  return await ServerUtils.authenticatedPost(`/web_api/quotes/${quoteId}/client_calculations`, {
    body: { clientCalculations },
    expectedResponse: [201],
  });
}

export async function getQuoteCoveragesContext() {
  const { data, rawResponse } = await ServerUtils.authenticatedGet('/web_api/contexts/quote_coverages_context', {
    expectedResponse: [200, 204],
  });

  if (rawResponse.status === 204) {
    return {};
  }

  return data;
}

export async function getQuoteCoveragesContextForAllBackendOptions(params) {
  const { data, rawResponse } = await ServerUtils.authenticatedGet('/web_api/contexts/quote_coverages_context/for_all_backend_options', {
    expectedResponse: [200, 204],
    query: params,
  });

  if (rawResponse.status === 204) {
    return {};
  }

  return data;
}

export async function getPaymentAuthorization() {
  const { data } = await ServerUtils.authenticatedPost('/web_api/payment_authorization', {
    expectedResponse: 201,
  });

  return data;
}

export async function createPolicy(bindParams) {
  const response = await ServerUtils.authenticatedPost('/web_api/policy', {
    expectedResponse: [201, 422],
    body: {
      bindParams,
    },
  });

  if (response.rawResponse.status === 201) {
    return response.data;
  }

  return response;
}

export async function getAutoQuoteDisclaimer(autoQuoteDisclaimerId) {
  const { data } = await ServerUtils.authenticatedGet(`/web_api/auto_quote_disclaimers/${autoQuoteDisclaimerId}`, {
    expectedResponse: 200,
  });

  return data;
}

export async function getQuotingRulesContext(market) {
  const { data } = await ServerUtils.authenticatedGet('/web_api/contexts/quoting_rules_context', {
    query: {
      market,
    },
  });
  return data;
}

export async function createSupportTicket(params) {
  const response = await ServerUtils.authenticatedPost('/web_api/support_tickets', {
    expectedResponse: [200],
    body: {
      ...params,
    },
  });

  return response;
}

export async function rerateWithBackendOptions(params) {
  const response = await ServerUtils.authenticatedPost('/web_api/backend_rating_options/rerate_with_backend_options', {
    expectedResponse: [201],
    body: {
      ...params,
    },
  });

  return response;
}

export async function markQuoteAsViewed({ quoteId }) {
  return await ServerUtils.authenticatedPatch('/web_api/quotes/mark_viewed', {
    body: {
      quoteId,
    },
  });
}

export async function getQuoteEstimate() {
  const { data } = await ServerUtils.authenticatedGet('/web_api/quotes/estimate_prices', {
    expectedResponse: [200, 204],
  });
  return data;
}

export async function findOrCreateZenDeskChatAbTest() {
  const { data } = await ServerUtils.authenticatedPut('/web_api/ab_tests/find_or_create_for_account', {
    body: {
      abTestName: 'root_web_zen_desk_chat_v2',
    },
    expectedResponse: [200],
  });

  return data;
}

export async function getPasswordAddPermittedExperience() {
  const { data } = await ServerUtils.authenticatedGet('/web_api/profile_creation_password_removal_password_add_permitted_experience', {
    expectedResponse: [200],
  });
  return data;
}

export async function patchPhoenixLoaderEligibility() {
  const { data } = await ServerUtils.authenticatedPatch('/web_api/test_drive_quote_loaders/eligibility', {
    expectedResponse: [200],
  });

  return data;
}

export async function transitionFromEstimateQuote(rateId) {
  return await ServerUtils.authenticatedPost('/web_api/quotes/transition_from_estimate', {
    expectedResponse: [201],
    body: {
      rateId,
    },
  });
}

export async function logQuoteViewedEvent(params) {
  return await ServerUtils.authenticatedPost('/web_api/quote_viewed_events', {
    expectedResponse: [201],
    body: {
      ...params,
    },
  });
}

export async function createCaliforniaQuoteCustomizationBucket() {
  const { data } = await ServerUtils.authenticatedPost('/web_api/california_quote_customization_bucket', {
    expectedResponse: [200],
  });

  return data;
}

export async function createQuoteTierAssignments() {
  return await ServerUtils.authenticatedPost('/web_api/quote_tier_assignments', {
    expectedResponse: [200],
  });
}

export function getQuoteTiersCoverageDescriptionBucketConfiguration() {
  return new NetworkRequestConfiguration({
    endpoint: 'coverage_description_experience',
    method: NetworkRequestConfiguration.Methods.PUT,
    successCodes: [200],
  });
}
